<template>
  <auth-layout :background-image="imgUrl" :hide-onboarding="false">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ $t("auth.update-password.welcome") }}
    </b-card-title>

    <!-- form -->
    <validation-observer ref="form">
      <b-form class="auth-reset-password-form mt-2">
        <!-- password -->
        <b-form-group
          :label="$t('fields.password')"
          label-for="reset-password-new"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required|min:8"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="reset-password-new"
                v-model="form.password"
                :type="password1FieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password1ToggleIcon"
                  @click="togglePassword1Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- confirm password -->
        <b-form-group
          label-for="reset-password-confirm"
          :label="$t('fields.password_confirmation')"
        >
          <validation-provider
            #default="{ errors }"
            name="password_confirmation"
            rules="required|confirmed:password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="reset-password-confirm"
                v-model="form.password_confirmation"
                :type="password2FieldType"
                class="form-control-merge"
                :state="errors.length > 0 ? false : null"
                name="password_confirmation"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password2ToggleIcon"
                  @click="togglePassword2Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          variant="primary"
          block
          type="submit"
          @click.prevent="validationForm"
          :disabled="loading"
        >
          <b-spinner small v-if="loading" />
          <span class="sr-only" v-if="loading">
            {{ $t("general.loading") }}</span
          >
          <span v-if="!loading">{{ $t("auth.update-password.action") }}</span>
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-2">
      <router-link :to="{ name: 'admin-dashboard' }">
        <feather-icon icon="ChevronLeftIcon" />
        {{ $t("auth.update-password.redirect-homepage") }}
      </router-link>
    </p>
  </auth-layout>
</template>

<script>
import AuthLayout from "./components/AuthLayout.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

import { resetPassword } from "@/api/auth.api";

export default {
  components: { AuthLayout, ValidationProvider, ValidationObserver },
  mixins: [togglePasswordVisibility],
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/reset-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  data() {
    return {
      loading: false,
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      form: {
        password: "",
        password_confirmation: "",
        email: "",
        token: "",
      },
      required,
      email,
      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    validationForm() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return;

        this.loading = true;
        const result = await resetPassword(this.form);
        if (result.error) {
          this.$refs.form.setErrors(result.error);
        } else if (result.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("auth.reset-password.success"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$router.push({ name: "login" });
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.query.token;
  },
};
</script>

<style>
</style>