<template>
  <div class="onboarding-item" :class="bg">
    <div class="onboarding-item_icon-container">
      <div class="onboard-item-icon">
        <feather-icon :icon="icon" />
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center">
      <h3 class="mb-0">{{ title }}</h3>
      <p class="mb-0">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "onboard-item",
  props: ["bg", "icon", "title", "subtitle"],
};
</script>

<style lang="scss">
.onboarding-item {
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 2rem;
  border-radius: 0.5rem;
}

.onboarding-item_icon-container {
  height: 100%;
  display: grid;
  place-items: center;
  margin-right: 2rem;
}

.onboard-item-icon {
  background-color: #00000028;
  border-radius: 9999px;
  padding: 1rem;
  aspect-ratio: 1/1;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }
}
</style>