<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <brand-logo />
        <h2 class="brand-text text-primary ml-1">Face Recognition</h2>
      </b-link>

      <!-- /Brand logo-->

      <b-col md="8" class="d-none d-lg-flex">
        <b-row>
          <b-col cols="12" class="d-none d-lg-flex justify-space-around">
            <div class="d-lg-flex align-items-center w-100 flex-grow-1">
              <b-img
                fluid
                :src="
                  require('@/assets/images/pages/login/auth-background.svg')
                "
                alt="Login V2"
                style="max-width: 300px"
                class="mx-auto p-2"
              />
            </div>
          </b-col>

          <!-- Left Text-->
          <b-col
            cols="12"
            class="d-none d-lg-flex align-items-center px-5"
            v-if="!hideOnboarding"
          >
            <onboarding />
          </b-col>
        </b-row>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <slot></slot>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import BrandLogo from "@core/layouts/components/Logo.vue";
import Onboarding from "./Onboarding.vue";
export default {
  name: "AuthLayout",
  props: ["backgroundImage", "hideOnboarding"],
  components: { BrandLogo, Onboarding },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
