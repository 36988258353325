<template>
  <div class="w-100 onboarding-container">
    <onboard-item
      bg="bg-light-primary"
      :title="`1. ${$t('onboarding.step1.title')}`"
      :subtitle="$t('onboarding.step1.subtitle')"
      icon="UserPlusIcon"
    />
    <onboard-item
      bg="bg-light-info"
      :title="`2. ${$t('onboarding.step2.title')}`"
      :subtitle="$t('onboarding.step2.subtitle')"
      icon="EditIcon"
    />
    <onboard-item
      bg="bg-light-warning"
      :title="`3. ${$t('onboarding.step3.title')}`"
      :subtitle="$t('onboarding.step3.subtitle')"
      icon="SmartphoneIcon"
    />
    <onboard-item
      bg="bg-light-success"
      :title="`4. ${$t('onboarding.step4.title')}`"
      :subtitle="$t('onboarding.step4.subtitle')"
      icon="ApertureIcon"
    />
  </div>
</template>

<script>
import OnboardItem from "./OnboardItem.vue";
export default {
  components: { OnboardItem },
  name: "onboarding",
};
</script>

<style lang="scss">
.onboarding-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  column-gap: 0.5rem;
  & > div {
    width: calc(50% - 0.5rem) !important;
  }
}
</style>