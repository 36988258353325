import axios from "@/libs/axios";

export async function register({ name, email, password }) {
    try {
        const { data } = await axios.post("/api/v1/auth/register", { name, email, password })
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}

export async function forgotPassword({ email }) {
    try {
        const { data } = await axios.post("/api/v1/auth/password/forgot-password", { email, })
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}

export async function resetPassword({ token, email, password, password_confirmation }) {
    try {
        const { data } = await axios.post("/api/v1/auth/password/reset-password", { token, email, password, password_confirmation })
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}

export async function resendEmailVerification() {
    try {
        const { data } = await axios.post("/api/v1/auth/email/verification-resend")
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}

export async function verifyEmail(token) {
    try {
        const { data } = await axios.post("/api/v1/auth/email/verify-otp-token", {'verification_code': token})
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}


export async function requestPasswordUpdate(id) {
    try {
        const { data } = await axios.post("/api/v1/auth/password/request-password-update", { user_id: id })
        return { data }
    } catch (error) {
        if (error.response.status === 422) {
            return { error: error.response.data.invalid_params }
        }
        return { error }
    }
}
